<template>
  <ChannelGrid v-if="channels" :channels="channels" :star-authors="starAuthors" />
</template>

<script>
import trackPageView from '@/utils/track-page-view'
import ChannelGrid from '@/components/channel/channel-grid'
import getChannelData from '@/components/channel/getChannelData'

import { generateMetaTags, generateTitle } from '@/utils/head-generators'
import { slugify } from '~/plugins/url-utils'

export default {
  components: {
    ChannelGrid,
  },
  asyncData(context) {
    return getChannelData(context)
  },
  data() {
    return {
      channels: null,
      starAuthors: null,
    }
  },
  head() {
    return {
      title: generateTitle(
        this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
        this.$config
      ),
      meta: [
        ...generateMetaTags({
          title: this.channels[0].attributes.pageTitle ? this.channels[0].attributes.pageTitle : '',
          description: this.channels[0].attributes.description ? this.channels[0].attributes.description : '',
          url: this.$route.path,
          socialImage: '/og-default.jpg',
          imageWidth: 512,
          imageHeight: 512,
          $config: this.$config,
        }),
        {
          name: 'cXenseParse:pageclass',
          content: 'frontpage',
        },
      ],
    }
  },
  computed: {
    darkmode() {
      if (!this.channels[0].relationships.boxes.length) {
        return false
      }
      if (!this.channels[0].relationships.boxes[0].relationships.articles.length) {
        return false
      }
      if (
        this.channels[0].relationships.boxes[0].relationships.articles[0].attributes.background === 1 &&
        (this.channels[0].relationships.boxes[0].attributes.templateAlias === 'cms.furche._boxes.ContentBox.hero' ||
          this.channels[0].relationships.boxes[0].attributes.templateAlias ===
            'cms.furche._boxes.ContentBox.dossier-box')
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    $route: {
      handler: function () {
        this.loadAds()
      },
      immediate: true,
    },
  },
  methods: {
    loadAds() {
      /*
       * Ads: Channel formats
       *
       * DESKTOP
       * SuperBanner-FullBanner1 (728x90): 22374
       * Sitebar/HPA/Sky (300x600 px, 160x600 px): 22375
       * Billboard (970x250 px oder 950x250 px): 23170
       * MedRec1 (300x250 px): 22373
       * MedRec2 (300x250 px): 23175
       *
       * MOBILE
       * mma-banner1-web: 23362
       * mma-banner2-web: 23366
       */
      if (process.client) {
        let formats = []
        if (window.innerWidth < 767) {
          formats = [{ id: 23362 }, { id: 23366 }]
        } else {
          formats = [{ id: 22374 }, { id: 22375 }, { id: 23170 }, { id: 22373 }, { id: 23175 }]
        }
        this.$store.dispatch('cope/load', {
          pageName: this.channels[0].attributes.pageName ? slugify(this.channels[0].attributes.pageName, '_') : 'ros',
          formats: formats,
          target: this.$config.adsCustomTarget + 'type=channel;homepage=true',
        })
      }
    },
  },
  mounted() {
    this.$store.dispatch('tinypass/execute')
    trackPageView({
      currentPageClass: 'frontpage',
    })
  },
}
</script>
